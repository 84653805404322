<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="手机号" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输手机号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="护照姓名" prop="passportName">
                <a-input v-model="queryParam.passportName" placeholder="请输入护照姓名" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="申根类型" prop="applyType">
                  <a-select v-model="queryParam.applyType">
                    <a-select-option :value="1">申根签证</a-select-option>
                    <a-select-option :value="1">美国签证</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="申请区域" prop="schengenArea">
                  <a-select v-model="queryParam.schengenArea">
                    <a-select-option :value="1">国内</a-select-option>
                    <a-select-option :value="1">英国</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否已婚" prop="isMarried">
                  <a-select v-model="queryParam.isMarried">
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否约到签证slot" prop="visaSlot">
                  <a-select v-model="queryParam.visaSlot">
                    <a-select-option :value="0">否</a-select-option>
                    <a-select-option :value="1">是</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="当前状态" prop="currentStatus">
                  <a-select v-model="queryParam.currentStatus">
                    <a-select-option :value="1">我已工作</a-select-option>
                    <a-select-option :value="2">我是学生</a-select-option>
                    <a-select-option :value="3">无业/自由职业</a-select-option>
                    <a-select-option :value="4">个体户</a-select-option>
                    <a-select-option :value="5">企业主</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="开始时间" prop="createBeginTime">
                  <a-date-picker
                    style="width: 100%"

                    v-model="queryParam.createBeginTime"
                    format="YYYY-MM-DD" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="结束时间" prop="createEndTime">
                  <a-date-picker
                    style="width: 100%"

                    v-model="queryParam.createEndTime"
                    format="YYYY-MM-DD" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="状态" prop="status">
                  <a-select v-model="queryParam.status">
                    <a-select-option :value="1">准备资料中</a-select-option>
                    <a-select-option :value="2">递签中</a-select-option>
                    <a-select-option :value="3">递签待反馈</a-select-option>
                    <a-select-option :value="4">已完成</a-select-option>
                    <a-select-option :value="-1">递签失败</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:visaApply:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:visaApply:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:visaApply:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['user:visaApply:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="submitTime" slot-scope="text, record">
          {{ parseTime(record.submitTime,'{y}-{m}-{d} {h}:{i}') }}
        </span>

        <span slot="validTime" slot-scope="text, record">
          {{ record.validTime }}{{ record.timeUnit }}
        </span>
        <span slot="noticeTime" slot-scope="text, record">
          {{ parseTime(record.noticeTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="updateTime" slot-scope="text, record">
          {{ parseTime(record.updateTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:visaApply:edit']" />
          <a @click="handleInfo(record)" v-hasPermi="['user:visaApply:edit']">
            <a-icon type="search" />详情
          </a>
          <a-divider type="vertical" v-hasPermi="['user:visaApply:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:visaApply:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageVisaApply,listVisaApply, delVisaApply } from '@/api/user/visaApply'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import moment from "moment";

export default {
  name: 'VisaApply',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile:null,
        userId: null,
        passportName: null,
        status: null,
        applyType: null,
        schengenArea: null,
        isMarried: null,
        currentStatus: null,
        visaSlot: null,
        failReason: null,
        applyCountry: null,
        submitPlace: null,
        submitTime: null,
        noticeTime: null,
        receiveTime: null,
        validTime: null,
        createBeginTime:null,
        createEndTime:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '手机号',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '护照姓名',
          dataIndex: 'passportName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f==1){
              return '准备资料中'
            }else if(f==2){
              return '递签中'
            }else if(f==3){
              return '递签待反馈'
            }else if(f==4){
              return '已完成'
            }else{
              return '递签失败'
            }
          }
        },
        {
          title: '申请类型',
          dataIndex: 'applyType',
          ellipsis: true,
          align: 'center',
          customRender(f) {
            if (f == 1) {
              return '美国签证'
            } else if (f == 2) {
              return '申根签证'
            }
          }
        },
        {
          title: '申请区域',
          dataIndex: 'schengenArea',
          ellipsis: true,
          align: 'center',
          customRender(f) {
            if (f == 1) {
              return '国内'
            } else if (f == 2) {
              return '英国'
            }
          }
        },
        {
          title: '是否已婚',
          dataIndex: 'isMarried',
          ellipsis: true,
          align: 'center',
          customRender(f) {
            if (f == 0) {
              return '否'
            } else if (f == 1) {
              return '是'
            }
          }
        },
        {
          title: '当前状态',
          dataIndex: 'currentStatus',
          ellipsis: true,
          align: 'center',
          customRender(f) {
            if (f == 1) {
              return '我已工作'
            } else if (f == 2) {
              return '我是学生'
            }else if(f==3){
              return '无业/自由职业'
            }else if(f==4){
              return '个体户'
            }else{
              return '企业主'
            }
          }
        },
        {
          title: '是否约到签证slot',
          dataIndex: 'visaSlot',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f==0){
              return '否'
            }else{
              return '是'
            }
          }
        },
        {
          title: '失败原因',
          dataIndex: 'failReason',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '申根国家',
        //   dataIndex: 'applyCountry',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '递签地点',
        //   dataIndex: 'submitPlace',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '递签时间',
        //   dataIndex: 'submitTime',
        //   scopedSlots: { customRender: 'submitTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '提醒时间',
        //   dataIndex: 'noticeTime',
        //   scopedSlots: { customRender: 'noticeTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '提醒次数',
          dataIndex: 'noticeNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '有效时长',
          dataIndex: 'validTime',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'validTime'}
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          scopedSlots: { customRender: 'updateTime' },
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询签证申请记录列表 */
    getList () {
      this.loading = true

      if(this.queryParam.createBeginTime){
        this.queryParam.createBeginTime = moment(this.queryParam.createBeginTime).format('YYYY-MM-DD 00:00:00')
      }
      if(this.queryParam.createEndTime){
        this.queryParam.createEndTime = moment(this.queryParam.createEndTime).format('YYYY-MM-DD 23:59:59')
      }

     pageVisaApply(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    handleInfo(record){
      this.$router.push({path:'/visa_manage/apply_info',query:{id:record.id}})
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        passportName: undefined,
        status: undefined,
        applyType: undefined,
        schengenArea: undefined,
        isMarried: undefined,
        currentStatus: undefined,
        visaSlot: undefined,
        failReason: undefined,
        applyCountry: undefined,
        submitPlace: undefined,
        submitTime: undefined,
        noticeTime: undefined,
        receiveTime: undefined,
        validTime: undefined,
        createBeginTime:null,
        createEndTime:null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delVisaApply(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/visaApply/export', {
            ...that.queryParam
          }, `签证申请记录_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
